import flatpickr from 'flatpickr';
import { French } from 'flatpickr/dist/l10n/fr.js'
import 'dayjs/locale/fr';
import dayjs from 'dayjs';

dayjs.locale('fr');

export function DateDefault(selector) {
    flatpickr(selector, {
        monthSelectorType: 'static',
    });
}

export class DateRangeSelector {
    constructor(element, options) {

        this.element = element;
        this.input = element.querySelector('.daterangepicker__input');
        this.flatpickerElement = element.querySelector('.daterangepicker__flatpicker');
        this.flatpickerButtons = element.querySelectorAll('.daterangepicker__btn');
        this.flatpickerButtonsClear = element.querySelectorAll('.daterangepicker__btn--clear');

        this.options = Object.assign({
            format: {
                view: 'D MMM',
                input: 'YYYY-MM-DD',
            },
            placeholder: {
                start: 'Date de début',
                end: 'Date de fin',
            },
            onChange: () => {},
            onClear: () => {},
        }, options);

        this.flatpickr = null;
        // this.custom();
        this.init();

        this.element.__daterangePicker = this;
    }

    getDateObject () {
        const dates = this.input.value !== '' ? this.input.value.split('/') : [];
        return dates.map((date) => (dayjs(date).toDate()));
    }

    init() {

        const defaultDate = this.getDateObject();
        this.setButtonDate(defaultDate);

        if (defaultDate.length === 0) {
            this.showPlaceholder();
        } else {
            this.element.classList.add('daterangepicker__value--selected');
        }

        this.flatpickr = flatpickr(this.flatpickerElement, {
            mode: 'range',
            locale: French,
            allowInput: true,
            dateFormat: 'd-m-Y',
            clickOpens: false,
            ignoredFocusElements: [window.document],
            defaultDate: defaultDate,
            closeOnSelect: false,
            position: 'auto center',
            showMonths: 2,
            onChange: (selectedDates, dateStr, instance) => {

                this.setButtonDate(selectedDates);

                if (selectedDates.length > 1) {
                    this.element.classList.add('daterangepicker__value--selected');
                    this.input.value = selectedDates.map((date) => (dayjs(date).format(this.options.format.input))).join('/');
                    this.showClearBtn(this.element.querySelector('.daterangepicker__btn.active'));
                    this.options.onChange(selectedDates);
                    this.flatpickr.close();
                }

            },
            onClose: () => {
                this.addClassToButton();
                this.showClearBtn();
            },
        });


        this.flatpickerButtonsClear.forEach((elm) => {
            elm.addEventListener('click', (e) => {
                e.stopPropagation();
                e.preventDefault();
                this.clear();
            });
        });

        this.flatpickerButtons.forEach((elm) => {
            elm.addEventListener('click', (e) => {
                // Close all other daterange
                document.querySelectorAll('.daterangepicker').forEach((elm) => {
                    if (elm.__daterangePicker && elm.__daterangePicker.flatpickr) {
                        elm.__daterangePicker.close();
                    }
                });

                this.addClassToButton(e.currentTarget);
                this.showClearBtn(e.currentTarget);
                this.flatpickr.toggle();
            });
        });

        document.addEventListener('click', (e) => {
            if (!e.target.closest('.flatpickr-calendar') && !e.target.closest('.daterangepicker')) {
                this.close();
            }
        });

    }

    close() {
        this.flatpickr.close();

        const defaultDate = this.getDateObject();
        this.setButtonDate(defaultDate);

        if (defaultDate.length === 0) {
            this.showPlaceholder();
        }
    }

    showPlaceholder() {
        const placeholderIndex = ['start', 'end'];
        this.flatpickerButtons.forEach((elm, key) => {
            elm.querySelector('.daterangepicker__btn--value').innerText = this.options.placeholder[placeholderIndex[key]];
        });
    }

    showClearBtn (button = null) {
        this.flatpickerButtons.forEach((elm) => {
            if (elm == button && this.input.value !== '') {
                elm.classList.add('clear-enable');
            } else {
                elm.classList.remove('clear-enable');
            }
        });
    }

    clear() {
        this.element.classList.remove('daterangepicker__value--selected');
        this.input.value = '';
        this.showPlaceholder();
        this.flatpickr.clear();
        this.options.onClear();
    }

    setButtonDate(selectedDates) {
        selectedDates.forEach((date, key) => {
            this.flatpickerButtons[key].querySelector('.daterangepicker__btn--value').innerText = dayjs(date).format(this.options.format.view);
        });
    }

    addClassToButton (button = null) {
        this.flatpickerButtons.forEach((elm) => {
            if (elm == button) {
                elm.classList.add('active');
            } else {
                elm.classList.remove('active');
            }
        });
    }
}
